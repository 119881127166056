<template>
<div 
  class="tamañoBanners" 
  style="background-color: #DFDFDF;"
>
  <v-container
    :class="`${$vuetify.breakpoint.mobile ? 'mobileBanners' : 'widthBanner'}`"
  >
    <v-row class="d-flex flex-row">
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
        sm="6"
        class="my-7"
      >
        <v-img
          class=" ml-md-auto mx-sm-auto "
          :src="require('@/assets/landing-img/section-img-uno.jpg')"
          max-width="600"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
        sm="6"
        align-self="center"
      >
        <v-img
          class="img-style mx-sm-auto mr-md-auto"
          :src="require('@/assets/landing-img/section-img-dos.jpg')"
          max-width="600"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .mobileBanners{
    height: auto !important;
  }
  .widthBanner{
    height: auto !important;
  }
  .tamañoBanners{
    width: auto !important;
    height: auto !important;
  }
</style>